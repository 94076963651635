@import "~scss/variables";

.card {
  border-radius: 10px;
  box-shadow: none;

  .cardText {
    margin: auto;
    max-width: 300px;
    padding-bottom: $md-default-padding !important;

    .info {
      display: flex;
      flex-direction: row;
      margin-bottom: $md-default-padding;

      i {
        cursor: pointer;
        flex: 0 0 36px;
        font-size: 28px;
        padding-right: $md-default-padding/2;
      }
    }
  }
}

@media (min-width: 700px), (max-height: 380px) {
  .card .cardText {
    max-width: 550px;
    display: flex;
    flex-direction: row;

    .info {
      order: 0;
      flex: 0 0 45%;
      margin-bottom: 0;
    }

    .qrscanner {
      order: 1;
      flex: 0 0 210px;
      margin-left: $md-default-padding;
    }
  }
}