@keyframes progress {
  /*0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }*/
  from {
    background-position: -200px 0;
  }
  to {
    background-position: 200px 0;
  }
}

.skeleton {
  position: relative;
  animation: progress 1.2s ease-in-out infinite;
  background-color: var(--placeholder-color-extralight);
  background-image: linear-gradient(90deg, var(--placeholder-color-extralight), #f5f5f5, var(--placeholder-color-extralight));
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  width: 100%;

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 70%;
    color: var(--placeholder-color-dark);
    font-style: italic;
  }
}
