.container {
  background: rgb(0, 169, 206);
  margin-top: 24px;
  padding: 16px;
  padding-bottom: 24px;
}

.title {
  color: white;
}

.body {
  color: white;
  padding-bottom: 8px;
}

.link {
  padding: 8px 16px 8px 16px;
  background: rgb(30, 34, 170);
  color: white !important;
  font-weight: 500;
  border-radius: 16px;
  text-transform: uppercase;
  white-space: nowrap;
}