@import "~scss/variables";

.card {
  font-size: inherit;
}

.viewContent {
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: $md-default-padding;
  }
}

.viewFooter {
  padding: 0 $md-default-padding $md-default-padding;
}