@import "~scss/variables";

.actions {
  display: flex;
  justify-content: space-between;

  :global(.md-btn--icon) {
    color: var(--placeholder-color);
  }
}

.buttons {
  display: inherit;

  .button {
    font-size: 15px;
    color: var(--placeholder-color-dark);
    margin: $md-default-padding $md-default-padding 0 $md-default-padding;
  }
}

.list {
  height: 100%;
  background: #{$md-primary-color}0a; // add transparency

  ul {
    background: rgba(255, 255, 255, 0);
  }
}