@import '../../../scss/variables';

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 40px;
  grid-template-areas: "body" "footer";
  text-align: center;
  height: 80vh;
  overflow-y: auto;

  .body {
    grid-area: body;

    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--secondary-text-color);
    background: var(--secondary-background-color);
    text-align: center;
    //padding: 20px;
    overflow-y: auto;

    .image {
      flex: 0 0 40px;
      padding-top: 40px;
      padding-bottom: 20px;
      img {
        height: 140px;
      }
    }

    .title {
      flex: 0 0 30px;
      font-size: 24px;
      font-weight: 700;
      padding: $md-default-padding;
      text-transform: uppercase;
    }

    .main {
      flex: 1;
      font-size: 18px;
      padding: 0 $md-default-padding $md-default-padding $md-default-padding;
    }

    .sub {
      font-weight: 700;
      padding-bottom: $md-default-padding;
    }
  }

  .footer {
    grid-area: footer;
  }
}
