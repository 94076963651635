@import "~scss/variables";

.container {
  text-align: center;

  .loading {
    font-size: 16px;
    font-weight: normal;
    margin: $md-default-padding/2 0;
    color: var(--primary-text-color);
    padding: $md-default-padding;
  }

  .panoramaContainer {
    padding-top: $md-default-padding;
  }
}