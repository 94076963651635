@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: row;
  font-weight: normal;

  .flag {
    flex: 0 36px;
  }

  .button {
    flex: 1;
    font-size: 22px;
    height: 60px;
    text-transform: none;
  }

  .icon {
    flex: 0 24px;
  }
}

.editContainer {
  padding: $md-default-padding;
}