@font-face {
  font-family: 'HDI Pro';
  src: url('./files/HDIPro-Book.woff2') format('woff2'),
      url('./files/HDIPro-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HDI Pro';
  src: url('./files/HDIPro-Bold.woff2') format('woff2'),
      url('./files/HDIPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

