@import "~scss/variables";

.card {
  font-size: inherit;
}

.viewContent {
  //padding: 0 $md-default-padding;

  .item {
    border-bottom: solid 1px var(--placeholder-color-light)
  }
}

.viewFooter {
  padding: 0 $md-default-padding $md-default-padding;
}