@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: row;

  .flag {
    flex: 0 36px;
  }

  .label {
    flex: 1;
    font-size: 16px;
  }

  .icon {
    flex: 0 24px;
  }
}

.editContainer {
  padding: $md-default-padding;
}