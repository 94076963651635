.container {
  .containerLabel {
    :global(.rw-datetime-picker) {
      padding-top: 40px;
    }
  }

  .containerNoLabel {

  }

  :global(.rw-popup-container) {
    font-size: 16px;
  }

  .dateTimePicker {
    font-size: 16px;

    :global(.rw-input) { // input field
      color: var(--primary-text-color);
      text-align: center;

    }

    :global(.rw-i) { // icons
      font-size: 20px;
    }
  }
}