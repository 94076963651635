@import "~scss/variables";

.container {
  p {
    font-size: 16px;
  }
}

.feedbackDialog {
  width: 90vw !important;
  max-width: 90vw !important;

  .text {
    font-weight: 700;
  }

  .starIcon {
    font-style: normal;
    font-size: 18px;
  }

  .starRatingPickerContainer {
  }

  .starRatingPickerItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px -8px;
    background: var(--placeholder-color-extralight);
    padding: $md-default-padding/2;
  }

  .starRatingPickerItemTitle {
    font-weight: 500;
  }

  .starRatingRating {
    display: flex;
    line-height: 24px;

    span {
      font-size: 13px;
      text-transform: uppercase;
    }

    :global(.dv-star-rating) {
      margin: 0 $md-default-padding/2;
    }
  }
}

