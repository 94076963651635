@import "~scss/variables";

.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: $md-default-padding/2;
  color: var(--secondary-text-color);
  background-color: var(--secondary-background-color);

  display: grid;
  grid-template-columns: 1fr 115px;
  grid-template-rows: 10px auto auto;
  grid-template-areas:
          "progressbar    progressbar"
          "title          percentage"
          "subtitle       percentage";
  grid-column-gap: 2px;
  align-items: center;
  z-index: 100;

  .progressbar {
    grid-area: progressbar;
  }

  .title {
    grid-area: title;
    padding-top: $md-default-padding/2;
    padding-left: $md-default-padding;
    font-size: 16px;
  }

  .subtitle {
    grid-area: subtitle;
    padding-left: $md-default-padding;
    padding-bottom: $md-default-padding/2;
    font-size: 16px;
  }

  .percentage {
    grid-area: percentage;
    justify-self: end;
    padding-right: $md-default-padding;
    font-size: 32px;
  }
}