@import "~scss/variables";

.container {
  margin-top: $md-default-padding*2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 24px;
    margin-bottom: $md-default-padding;
  }

  .text {

  }

  .featureContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: white;
    padding: $md-default-padding;
    background: var(--primary-background-color);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: $md-default-padding 0;

    ul {
      padding-left: $md-default-padding;
    }
  }

  .contactContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: $md-default-padding;
    background: #fed530;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    button {
      font-size: 16px;
      margin: $md-default-padding;
      background: var(--primary-background-color);
      color: white;
    }
  }

  .footer {
    margin: $md-default-padding*2 0;
    text-align: center;
    color: gray;
    font-weight: 700;

    div {
      padding: $md-default-padding/4;
    }
  }
}