@import "~scss/variables";

.toolbar {
  //border-radius: 12px 12px 0 0;
}

.previewContainer {
  overflow: hidden;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  .tab {
    display: none;
  }

  .swipeableViewsContainer {
    flex: 1;
  }

  main {
    padding-top: 1px; // fix scroll glitch
  }

  header {
    min-height: 50px;
  }
}