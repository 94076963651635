@import "~scss/variables";

.card {
  border-radius: 10px;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-content: center;

  .cardText {
    //flex: 0 0 250px;
    margin: auto;
    max-width: 280px;
    padding-bottom: $md-default-padding !important;

    .webcode {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $md-default-padding;

      input {
        flex: 1 1;
        font-size: 20px;
        text-align: center;
        color: var(--default-main-color);
        letter-spacing: 2px;
        border: 2px solid var(--secondary-background-color);
        border-radius: 6px;
        font-weight: 500;
        -webkit-appearance: none; /* remove ugly shadow on iOS */
        min-width: 0; // required for FF
      }
      input::placeholder {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 28px; /* make sure text looks centered */
      }
      input:focus {
        outline: none;
      }

      i {
        flex: 0 0 36px;
        font-size: 28px;
        padding-left: $md-default-padding/2;
        cursor: pointer;
      }
    }

    .image {
      flex: 1 0;
      img {
        width: 100%;
      }
    }
  }
}

@media (max-height: 380px) and (orientation: landscape) {
  .card .cardText {
    .image {
      display: none;
    }
  }
}

@media (max-height: 420px) and (min-width: 700px) and (orientation: landscape) {
  .card .cardText {
    max-width: 550px;
    display: flex;
    flex-direction: row;
    align-content: center;

    .webcode {
      flex: 0 0 250px;
      margin-right: $md-default-padding;
    }

    .image {
      flex: 1 0;
      margin-left: $md-default-padding;
    }
  }
}