@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  padding: $md-default-padding 0;
  max-width: 350px;
  margin: auto;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-text-color);
  }

  .textField {
    border: #ccc 1px solid;
    border-radius: 4px;
    padding: $md-default-padding/2;
    background: var(--aida-yellow);
    text-align: center;
    color: var(--primary-text-color);

    input {
      text-align: center;
      margin-top: 0;
      font-size: 18px;
      color: var(--primary-text-color);
    }
    input::placeholder {
      color: var(--primary-text-color);
    }

    hr {
      display: none;
    }
  }

  .dateTimePicker {
    :global(.rw-input) {
      font-size: 18px;
    }
  }

  .button {
    color: var(--primary-background-color);
    background: var(--aida-blue);
    font-size: 18px;
    border-color: var(--aida-blue);
    border-radius: 2px;
    text-transform: none;
    line-height: 18px;
  }
}

.confirmationDialog {
  color: var(--aida-primary-text-color);
}

.editContainer {
  padding: $md-default-padding;
}