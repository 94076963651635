@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: row;
  margin-top: $md-default-padding;

  .card {
    width: 100%;
    position: relative;

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    .editButton {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: $md-default-padding;
      margin-right: $md-default-padding;
    }
  }

  :global(.md-card-text) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.editContainer {
  padding: $md-default-padding;
}