@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Futura Book '),
    local('Futura-Book'),
    url('./files/Futura.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/Futura.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Futura Bold '),
    local('Futura-Bold'),
    url('./files/Futura-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/Futura-Bold.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
          local('Futura Medium '),
          local('Futura-Medium'),
          url('./files/Futura-Medium.woff2') format('woff2'), /* Super Modern Browsers */
          url('./files/Futura-Medium.woff') format('woff'); /* Modern Browsers */
}