@import url(../../fonts/typeface-opensans/opensans-fonts.css);
:root {
  --aida-blue: #3399CC;
  --aida-red: #CC3300;
  --aida-yellow: #FFCC00;
  --aida-green: #339900;
  --aida-textblue: #7AA5C6;
  --aida-primary-text-color: #004461; }

.theme-aida {
  --primary-background-color: white;
  --primary-text-color: var(--aida-primary-text-color);
  --secondary-background-color: var(--aida-blue);
  --secondary-text-color: white;
  --tertiary-background-color: var(--aida-red);
  --tertiary-text-color: white;
  --default-main-color: #004461;
  --default-sub-color: white;
  font-family: Open Sans, sans-serif; }
  .theme-aida .md-btn--color-primary-active {
    background: rgba(var(--primary-background-color), 0.12); }
  .theme-aida .md-btn--color-secondary-active {
    background: rgba(var(--secondary-background-color), 0.12); }
  .theme-aida .md-card-title--contextual {
    background: var(--secondary-background-color); }
  .theme-aida .md-card-title--title-contextual {
    color: var(--secondary-background-color); }
  .theme-aida .md-picker-header {
    background: var(--primary-background-color); }
  .theme-aida .md-calendar-date--btn::after {
    background: var(--primary-background-color); }
  .theme-aida .md-clock-hand::before, .theme-aida .md-clock-hand::after {
    background: var(--primary-background-color); }
  .theme-aida .md-circular-progress-path {
    stroke: var(--secondary-background-color); }
  .theme-aida .md-slider-thumb--on,
  .theme-aida .md-slider-track-fill {
    background: var(--primary-background-color); }
  .theme-aida .md-slider-thumb--discrete-on::after {
    border-top-color: var(--primary-background-color); }
  .theme-aida .md-tab-indicator {
    background: var(--secondary-background-color); }
  .theme-aida .md-divider--text-field:not(.md-divider--text-field-error)::after {
    background: var(--primary-background-color); }
  .theme-aida .md-text--theme-primary {
    color: var(--primary-background-color); }
  .theme-aida .md-ink--primary .md-ink {
    background: rgba(var(--primary-background-color), 0.26); }
  .theme-aida .md-background--primary {
    background: var(--primary-background-color);
    color: var(--primary-text-color); }
  .theme-aida .md-text--theme-secondary {
    color: var(--secondary-background-color); }
  .theme-aida .md-ink--secondary .md-ink {
    background: rgba(var(--secondary-background-color), 0.26); }
  .theme-aida .md-background--secondary {
    background: var(--secondary-background-color); }
  @media screen and (min-width: 1025px) {
    .theme-aida .md-background--primary-hover:hover {
      background: rgba(var(--primary-background-color), 0.9); }
    .theme-aida .md-background--secondary-hover:hover {
      background: rgba(var(--secondary-background-color), 0.9); } }
  .theme-aida .md-progress--linear {
    background: #ff8a80;
    height: 3px;
    overflow: hidden;
    position: relative;
    width: 100%; }
  .theme-aida .md-progress--linear-active {
    background: #d50000; }
  .theme-aida .md-progress--linear-determinate {
    height: 100%;
    position: absolute;
    z-index: 1; }
  .theme-aida .md-progress--linear-indeterminate::before, .theme-aida .md-progress--linear-indeterminate::after {
    background: inherit;
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    will-change: left, right;
    z-index: 1; }
  .theme-aida .md-progress--linear-indeterminate::before {
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-name: md-linear-indeterminate;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .theme-aida .md-progress--linear-indeterminate::after {
    animation-delay: 1.15s;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-name: md-linear-indeterminate-short;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .theme-aida .md-progress--linear-query::before, .theme-aida .md-progress--linear-query::after {
    animation-direction: reverse; }

@keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }
  .theme-aida h1, .theme-aida h2, .theme-aida h3, .theme-aida h4, .theme-aida h5, .theme-aida h6, .theme-aida p, .theme-aida button, .theme-aida input, .theme-aida textarea, .theme-aida html {
    font-family: Open Sans, sans-serif; }
  .theme-aida .md-subheader {
    color: var(--primary-text-color); }
  .theme-aida .md-tab--inactive {
    color: inherit; }
  .theme-aida .md-progress--linear {
    margin-top: 0;
    height: 8px; }
