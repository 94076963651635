@import "~scss/variables";
@import "./image-gallery-variables.scss";
//@import "image-gallery-material-icons.scss"; // DOES NOT WORK!! currently in _variables.scss,

.container {
  .lightboxImageContainer {
    display: block;
    overflow: auto; // important, otherwise will not take space
  }

  .imageGalleryContainer {
    max-width: 350px;
    margin: auto;

    :global(.image-gallery-thumbnails-wrapper) {
      margin-top: 40px;
    }

    :global(.image-gallery-thumbnails) {
      display: flex;
      align-items: center;
      max-height: 51px; // important, otherwise thumbnails get distorted
    }

    :global(.image-gallery-fullscreen-button) {
      bottom: -32px;

      &::before {
        font-size: 32px;
        padding: 0;
      }
    }

    .imageGalleryCustomControls {
      position: absolute;
      bottom: -40px;
      left: 0;
    }

    .imageGalleryOriginalClass {
      line-height: 240px; // important to get image vertically centered

      // fix small image font in image-gallery
      :global(.image-gallery-image) {
        span {
          @media (max-width: $ig-screen-sm-min) {
            bottom: 15px;
            font-size: 16px;
            padding: 8px 15px;
          }
        }
      }
    }

    .imageGalleryThumbnailClass {

    }
  }
}