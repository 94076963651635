@import "~scss/variables";

.container {
  .fields {
    padding: $md-default-padding;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: $md-default-padding;

    :global(.md-divider) {
      display: none;
    }
  }

  .buttonContainer {
    padding: 0 $md-default-padding/2;

    .button {
      width: 100%;
      font-size: 22px;
      height: 60px;
      text-transform: none;
      background-color: var(--tertiary-background-color) !important;
    }
  }

}