@import "~scss/variables";

.card {
  font-size: 18px;
}

.content {
  ul > div {
    padding: $md-default-padding/2 $md-default-padding;
  }
}