@import url(../../fonts/typeface-futura/futura-fonts.css);
:root {
  --helvetia-purple: #885FA0;
  /* almost $md-deep-purple-400 */
  --helvetia-red: #E2001A;
  /* almost $md-red-a-700 */
  --helvetia-blue: #00A2AC;
  /* almost $md-cyan-700 */
  --helvetia-gray: #8b9196;
  --helvetia-dark-gray: #787c81; }

.theme-helvetia {
  --primary-background-color: white;
  /*var(--helvetia-purple)*/
  --primary-text-color: black;
  /*white;*/
  --secondary-background-color: var(--helvetia-blue);
  --secondary-text-color: white;
  --tertiary-background-color: var(--helvetia-red);
  --tertiary-text-color: white;
  --default-main-color: black;
  --default-sub-color: white;
  /* @mixin react-md-theme-everything(
    $primary-color,
    $secondary-color,
    $light-theme: $md-light-theme,
    $html-class-name: null,
    $error-color: $md-error-color,
    $progress-swatch: $md-linear-progress-swatch,
    $primary-opacity: $md-primary-hover-opacity,
    $primary-background-text-color: $md-primary-background-text-color,
    $secondary-opacity: $md-secondary-hover-opacity,
    $secondary-background-text-color: $md-secondary-background-text-color,
    $data-table-contextual-fallback-color: $md-data-table-contextual-fallback-color,
    $linear-progress-fallback-color: $md-linear-progress-fallback-color,
    $switch-ball-fallback-color: $md-switch-ball-fallback-color
  ) */
  font-family: Futura, sans-serif; }
  .theme-helvetia .md-btn--color-primary-active {
    background: rgba(var(--primary-background-color), 0.12); }
  .theme-helvetia .md-btn--color-secondary-active {
    background: rgba(var(--helvetia-blue), 0.12); }
  .theme-helvetia .md-card-title--contextual {
    background: var(--helvetia-blue); }
  .theme-helvetia .md-card-title--title-contextual {
    color: var(--helvetia-blue); }
  .theme-helvetia .md-picker-header {
    background: var(--primary-background-color); }
  .theme-helvetia .md-calendar-date--btn::after {
    background: var(--primary-background-color); }
  .theme-helvetia .md-clock-hand::before, .theme-helvetia .md-clock-hand::after {
    background: var(--primary-background-color); }
  .theme-helvetia .md-circular-progress-path {
    stroke: var(--helvetia-blue); }
  .theme-helvetia .md-slider-thumb--on,
  .theme-helvetia .md-slider-track-fill {
    background: var(--primary-background-color); }
  .theme-helvetia .md-slider-thumb--discrete-on::after {
    border-top-color: var(--primary-background-color); }
  .theme-helvetia .md-tab-indicator {
    background: var(--helvetia-blue); }
  .theme-helvetia .md-divider--text-field:not(.md-divider--text-field-error)::after {
    background: var(--primary-background-color); }
  .theme-helvetia .md-text--theme-primary {
    color: var(--primary-background-color); }
  .theme-helvetia .md-ink--primary .md-ink {
    background: rgba(var(--primary-background-color), 0.26); }
  .theme-helvetia .md-background--primary {
    background: var(--primary-background-color);
    color: var(--primary-text-color); }
  .theme-helvetia .md-text--theme-secondary {
    color: var(--helvetia-blue); }
  .theme-helvetia .md-ink--secondary .md-ink {
    background: rgba(var(--helvetia-blue), 0.26); }
  .theme-helvetia .md-background--secondary {
    background: var(--helvetia-blue); }
  @media screen and (min-width: 1025px) {
    .theme-helvetia .md-background--primary-hover:hover {
      background: rgba(var(--primary-background-color), 0.9); }
    .theme-helvetia .md-background--secondary-hover:hover {
      background: rgba(var(--helvetia-blue), 0.9); } }
  .theme-helvetia .md-progress--linear {
    background: #ff8a80;
    height: 3px;
    overflow: hidden;
    position: relative;
    width: 100%; }
  .theme-helvetia .md-progress--linear-active {
    background: #d50000; }
  .theme-helvetia .md-progress--linear-determinate {
    height: 100%;
    position: absolute;
    z-index: 1; }
  .theme-helvetia .md-progress--linear-indeterminate::before, .theme-helvetia .md-progress--linear-indeterminate::after {
    background: inherit;
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    will-change: left, right;
    z-index: 1; }
  .theme-helvetia .md-progress--linear-indeterminate::before {
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-name: md-linear-indeterminate;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .theme-helvetia .md-progress--linear-indeterminate::after {
    animation-delay: 1.15s;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-name: md-linear-indeterminate-short;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .theme-helvetia .md-progress--linear-query::before, .theme-helvetia .md-progress--linear-query::after {
    animation-direction: reverse; }

@keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }
  .theme-helvetia h1, .theme-helvetia h2, .theme-helvetia h3, .theme-helvetia h4, .theme-helvetia h5, .theme-helvetia h6, .theme-helvetia p, .theme-helvetia button, .theme-helvetia input, .theme-helvetia textarea, .theme-helvetia html {
    font-family: Futura, sans-serif; }
  .theme-helvetia .md-subheader {
    font-size: 16px;
    color: var(--primary-text-color); }
  .theme-helvetia .md-tab--inactive {
    color: inherit; }
  .theme-helvetia .md-progress--linear {
    margin-top: 0;
    height: 8px; }
  .theme-helvetia .md-floating-label--floating {
    font-size: 14px;
    color: var(--helvetia-dark-gray); }
