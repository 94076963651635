@import "~scss/variables";

.content {
  padding: $md-default-padding;
}

.dropzone {
  /* reset styles in order not to conflict with "styles-dropzone" class */
  border: none;

  input {
    width: 100%;
  }
}

.floatingButtons {
  /*position: sticky;
  position: -webkit-sticky;
  bottom: 0;*/
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 50px;

  display: flex;
  flex-direction: row;
  text-align: center;

  .primaryFloatingButton {
    flex: 0 0 60%;
    background-color: var(--primary-color);
  }

  /*.secondaryFloatingButton {
    flex: 0 0 33%;
    background-color: var(--helvetia-blue);
  }*/

  .tertiaryFloatingButton {
    flex: 0 0 40%;
    background-color: var(--secondary-color);
  }
}

.loadingDots {
  span {
    background-color: white;
  }
}

.list {
  :global(.md-subheader) {
    padding: 0;
  }
}