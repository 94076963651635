@import "~scss/variables";

$md-colors-warn-md: false;

// import Futura font family
@import "~fonts/typeface-futura/futura-fonts.css";

:root {
  --helvetia-purple: #885FA0; /* almost $md-deep-purple-400 */
  --helvetia-red: #E2001A; /* almost $md-red-a-700 */
  --helvetia-blue: #00A2AC; /* almost $md-cyan-700 */
  --helvetia-gray: #8b9196;
  --helvetia-dark-gray: #787c81;
  --helvetia-selected: #DAEEF0; /* black squeeze */
}

.theme-helvetia {
  --primary-color: var(--helvetia-purple);
  --secondary-color: var(--helvetia-red);
  --tertiary-color: var(--helvetia-blue);

  --primary-background-color: white; /*var(--helvetia-purple)*/
  --primary-text-color: black; /*white;*/
  --secondary-background-color: var(--helvetia-blue);
  --secondary-text-color: white;
  --tertiary-background-color: var(--helvetia-red);
  --tertiary-text-color: white;
  --default-main-color: black;
  --default-sub-color: white;

  /* @mixin react-md-theme-everything(
    $primary-color,
    $secondary-color,
    $light-theme: $md-light-theme,
    $html-class-name: null,
    $error-color: $md-error-color,
    $progress-swatch: $md-linear-progress-swatch,
    $primary-opacity: $md-primary-hover-opacity,
    $primary-background-text-color: $md-primary-background-text-color,
    $secondary-opacity: $md-secondary-hover-opacity,
    $secondary-background-text-color: $md-secondary-background-text-color,
    $data-table-contextual-fallback-color: $md-data-table-contextual-fallback-color,
    $linear-progress-fallback-color: $md-linear-progress-fallback-color,
    $switch-ball-fallback-color: $md-switch-ball-fallback-color
  ) */

  @include react-md-theme-everything(
    var(--primary-background-color), /*var(--helvetia-purple)*/
    var(--helvetia-blue),
    true,
    null,
    $md-error-color,
    $md-linear-progress-swatch,
    $md-primary-hover-opacity,
    var(--primary-text-color),
    $md-secondary-hover-opacity,
    $md-secondary-background-text-color,
    $md-data-table-contextual-fallback-color,
    $md-linear-progress-fallback-color,
    $md-switch-ball-fallback-color
  );
  @include react-md-progress-linear($secondary-color: $md-red-a-700, $swatch: 100);

  font-family: Futura, sans-serif;

  h1, h2, h3, h4, h5, h6, p, button, input, textarea, html {
    font-family: Futura, sans-serif;
  }

  .md-subheader {
    font-size: 16px;
    color: var(--primary-text-color);
  }

  .md-tab--inactive {
    color: inherit;
  }

  .md-progress--linear {
    margin-top: 0;
    height: 8px;
  }

  .md-btn--text {
    text-transform: none;
    font-size: 18px;
  }

  .md-floating-label--floating { // make floating labels above Input fields mono-colored at bigger
    font-size: 14px;
    color: var(--helvetia-dark-gray);
  }
}