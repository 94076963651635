@import "~scss/variables";

.container {
  :local(section) {
    height: calc(100vh - #{$md-toolbar-height});
    margin-top: $md-toolbar-height;
    overflow-y: auto;
  }

  .text {
    padding: $md-default-padding $md-default-padding 0;
  }

  .title {
    padding: $md-default-padding $md-default-padding 0;
    font-size: 22px;
    font-weight: 700;
  }
}
/*
@supports (-webkit-overflow-scrolling: touch) {
  @media (orientation: portrait) {
    // CSS specific to iOS devices
    :local(section) {
      padding-bottom: $md-ios-offset; // needed since 100vh includes also Safari menu and navigation bars
    }
  }
}*/