@import "~scss/variables";

:root {
  --primary-background-color: #00acc1; //#F44336; // $md-red-500
  --primary-text-color: white;
  --secondary-background-color: #FF8A80; // $md-red-a-100
  --secondary-text-color: white;
  --default-main-color: black;
  --default-sub-color: #00acc1; // #F44336;
  --placeholder-color: #a9a9a9;
  --placeholder-color-extralight: #eee;
  --placeholder-color-light: #a9a9a97a;
  --placeholder-color-dark: #7d7d7d;

  --target-public: #5FA29B;
  --target-shared: #FCCE5C;
  --target-private: #DF5C5C;

  --sensotix-blue: #0c3a51;
  --sensotix-green: #47b475;
}

/* global styles for all profiles */
@import "~profiles/styles";

@include react-md-everything;
