@import "~scss/variables";

.viewContent {
  ul > div {
    padding: $md-default-padding/2 $md-default-padding;
    border-bottom: 1px solid var(--placeholder-color-light);
  }
  div:last-child {
    border: none;
  }
}

.viewFooter {
  padding: 0 $md-default-padding $md-default-padding;
}

.editContainer {
  padding: $md-default-padding;
}