@import "~scss/variables";

.container {
  padding: $md-default-padding;
  color: var(--primary-text-color);

  .header {
    font-size: 18px;
    font-weight: 700;
  }

  .list {
    margin-bottom: 80px;

    p {
      font-size: 16px;
      color: var(--primary-text-color);
    }

    .subheader {
      font-weight: 700;
      padding-top: $md-default-padding;
      padding-bottom: $md-default-padding/2;
    }

    .textblock {
    }

    .footer {
      padding-top: $md-default-padding;
      font-weight: 500;
    }
  }
}