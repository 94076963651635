@import "~scss/variables";

.card {
  font-size: 18px;
}

.container {
  font-size: 18px;
  font-weight: 500;
  padding: 0 $md-default-padding;
  border: none;
}