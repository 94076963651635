.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60% 8px auto;
  grid-template-areas: "top" "progress" "bottom";
  text-align: center;

  .top {
    grid-area: top;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #feecd9;
  }

  .progressbar {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .bottom {
    grid-area: bottom;
    background: #f44336;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 12px;
    font-size: 16px;

    h2 {
      color: white;
    }
  }
}