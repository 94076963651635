// TODO: variable import example: https://github.com/css-modules/css-modules/blob/master/docs/values-variables.md

@import "~scss/variables";

.container {
  :global(.md-expansion-panel) {
    margin: $md-default-padding/2 $md-default-padding $md-default-padding/2 $md-default-padding;
    background: var(--secondary-background-color);
  }

  div, i, p {
    color: white;
  }
}