@import "~scss/variables";

.textfield {
  hr {
    display: none;
  }

  label {
    font-size: 18px !important;
    color: var(--primary-text-color) !important;
  }

  textarea {
    font-size: 18px;
    font-weight: bold !important;
  }
}