@import "~scss/variables";

$md-colors-warn-md: false;

:root {
  --stx-dark-blue: #1e22aa; /* almost $md-deep-purple-400 */
  --stx-cyan: #00a9ce;
  --stx-light-blue: #b9d9eb; /* almost $md-red-a-700 */
  --stx-yellow: #ffd700; /* almost $md-cyan-700 */
  --stx-red: #f65275;
  --stx-gray: #8b9196;
  --stx-dark-gray: #787c81;
  --stx-selected: #DAEEF0; /* black squeeze */
}

.theme-folder {
  --primary-color: var(--stx-cyan);
  --secondary-color: var(--stx-dark-blue);
  --tertiary-color: var(--stx-cyan);

  --primary-background-color: white;
  --primary-text-color: #141412; /*rangoon-green;*/
  --secondary-background-color: var(--stx-cyan);
  --secondary-text-color: white;
  --tertiary-background-color: var(--stx-red);
  --tertiary-text-color: white;
  --default-main-color: black;
  --default-sub-color: white;

  @include react-md-theme-everything(
    var(--primary-background-color),
    var(--stx-cyan),
    true,
    null,
    $md-error-color,
    $md-linear-progress-swatch,
    $md-primary-hover-opacity,
    var(--primary-text-color),
    $md-secondary-hover-opacity,
    $md-secondary-background-text-color,
    $md-data-table-contextual-fallback-color,
    $md-linear-progress-fallback-color,
    $md-switch-ball-fallback-color
  );
  @include react-md-progress-linear($secondary-color: $md-red-a-700, $swatch: 100);

  .md-subheader {
    font-size: 16px;
    color: var(--primary-text-color);
  }

  .md-tab--inactive {
    color: inherit;
  }

  .md-progress--linear {
    margin-top: 0;
    height: 8px;
  }

  .md-btn--text {
    text-transform: none;
    font-size: 18px;
  }

  .md-floating-label--floating { // make floating labels above Input fields mono-colored at bigger
    font-size: 14px;
    color: var(--stx-dark-gray);
  }
}