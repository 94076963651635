@import "~scss/variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $md-default-padding/2;

  .main {
    font-size: 16px;
    font-weight: normal;
    margin: $md-default-padding/2 0;
    text-align: center;
    color: var(--aida-blue);
    padding-top: $md-default-padding;
  }

  .sub {
    font-size: 16px;
    font-weight: normal;
    margin: $md-default-padding/4 0;
    text-align: center;
    color: var(--primary-text-color);
  }

  .subBold {
    font-size: 16px;
    font-weight: 500;
    margin: $md-default-padding/4 0;
    text-align: center;
    color: var(--primary-text-color);
  }

  .tooltipText {
    border-bottom: 2px dotted var(--aida-blue);
  }
}