@import "~scss/variables";

.container {
  height: calc(100vh - #{$md-toolbar-icontabs-height});
  overflow-y: auto;

  @supports (-webkit-overflow-scrolling: touch) {
    @media (orientation: portrait) {
      /* CSS specific to iOS devices */
      padding-bottom: $md-ios-offset; // needed since 100vh includes also Safari menu and navigation bars
    }
  }
}