@import "~scss/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary-background-color);
  color: white;
  height: 100vh;
  padding: $md-default-padding;

  .backButton {
    position: absolute;
    left: 2*$md-default-padding;
    top: 2*$md-default-padding;
  }

  h1 {
    color: inherit;
    font-size: 80px;
    margin-bottom: 40px;
  }

  h6 {
    color: yellow;
  }

  p {
    color: inherit;
    text-align: center;
    font-size: 18px;
  }

  button {
    background: white;
    color: var(--primary-background-color);
  }

  footer {
    position: absolute;
    bottom: 50px;
  }

}