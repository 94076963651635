@import "~scss/variables";

.container {
  display: flex;
  justify-content: space-between;
  z-index: 15;
  padding: 0 $md-default-padding;
  background: var(--primary-background-color);

  button {
    color: var(--primary-text-color) !important;
  }
}