@import "~scss/variables";

.addButton {
  bottom: 90px !important;
}

.tab {
  height: calc(100vh - #{$md-toolbar-icontabs-height});
  overflow-y: auto;
  margin-bottom: 80px;

  :global(.md-tile-content--left-icon) { // make sure icons don't stick next to titles
    padding-left: 24px;
  }
}

.tabsContainer {
  :global(.md-tab) {
    @media screen and (min-width: 1025px) {
      max-width: unset;
      min-width: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  @media (orientation: portrait) {
    /* CSS specific to iOS devices */
    .tab {
      padding-bottom: $md-ios-offset; // needed since 100vh includes also Safari menu and navigation bars
    }
  }
}