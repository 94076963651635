@import "~scss/variables";

.container {
  header {
    background: var(--secondary-background-color);
  }

  section {
    height: calc(100vh - #{$md-toolbar-height});
    margin-top: $md-toolbar-height;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .subheader {
    color: var(--secondary-background-color);
  }

  :global(.md-panel-content) {
    color: gray;
  }
}