@import "~scss/variables";

.container {
  padding: $md-default-padding/2 0;
  border-bottom: 1px solid var(--placeholder-color-light);

  :last-child {
    border: none;
  }

  .content {
    padding: 0 $md-default-padding;

    .item {
      border-bottom: 1px solid var(--placeholder-color-light)
    }
  }

  .footer {
    padding: 0 $md-default-padding $md-default-padding;
  }
}

.editContainer {
  padding: $md-default-padding;
}