@import "~scss/variables";

.header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--placeholder-color-light);

  .title {
    flex: 1 0;
    //background: lightgreen;
    padding: $md-default-padding/4 $md-default-padding/2 $md-default-padding/4 $md-default-padding/2;

    .main {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    .sub {
      margin: $md-default-padding/4 0 0 0;
      display: flex;

      h6 {
        font-size: 15px;
        text-transform: uppercase;
        color: var(--placeholder-color-dark);
        margin: 0 $md-default-padding/2 0 0;
      }
    }

    i {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      font-size: 18px;
    }
  }

  .actions {
    flex: 0 0 24px;
    //background: lightgreen;
    padding: $md-default-padding;
  }
}

.target {
  flex: 0 0 32px;
  text-align: center;
  padding-top: 5px;

  i {
    color: white;
  }
}

.target-public {
  composes: target;
  background: var(--target-public);
}

.target-shared {
  composes: target;
  background: var(--target-shared);
}

.target-private {
  composes: target;
  background: var(--target-private);
}

