.drawer {
  //border-radius: 12px 12px 0 0;
  //overflow-y: auto;

  :local(header) {
    //border-radius: 12px 12px 0 0;
    //overflow-y: hidden;
  }
}

.container {
  height: 100%;
  overflow-y: hidden;
}
