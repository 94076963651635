@import "~scss/variables";

.styles-dropzone {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed var(--placeholder-color-light) !important;
  border-radius: 8px;
  margin-top: $md-default-padding/2;
}