@import "~scss/variables";

$md-colors-warn-md: false;

// import OpenSans font family
@import "~fonts/typeface-opensans/opensans-fonts.css";


:root {
  --aida-blue: #3399CC;
  --aida-red: #CC3300;
  --aida-yellow: #FFCC00;
  --aida-green: #339900;
  --aida-textblue: #7AA5C6;
  --aida-primary-text-color: #004461;
}

.theme-aida {
  --primary-color: var(--aida-red);
  --secondary-color: var(--aida-green);
  --tertiary-color: var(--aida-blue);

  --primary-background-color: white;
  --primary-text-color: var(--aida-primary-text-color);
  --secondary-background-color: var(--aida-blue);
  --secondary-text-color: white;
  --tertiary-background-color: var(--aida-red);
  --tertiary-text-color: white;
  --default-main-color: #004461;
  --default-sub-color: white;

  @include react-md-theme-everything(
    var(--primary-background-color),
    var(--secondary-background-color),
    true,
    null,
    $md-error-color,
    $md-linear-progress-swatch,
    $md-primary-hover-opacity,
    var(--primary-text-color),
    $md-secondary-hover-opacity,
    $md-secondary-background-text-color,
    $md-data-table-contextual-fallback-color,
    $md-linear-progress-fallback-color,
    $md-switch-ball-fallback-color
  );
  @include react-md-progress-linear($secondary-color: $md-red-a-700, $swatch: 100);



  font-family: Open Sans, sans-serif;

  h1, h2, h3, h4, h5, h6, p, button, input, textarea, html {
    font-family: Open Sans, sans-serif;
  }

  .md-subheader {
    color: var(--primary-text-color);
  }

  .md-tab--inactive {
    color: inherit;
  }

  .md-progress--linear {
    margin-top: 0;
    height: 8px;
  }
}