@import "~scss/variables";

.container {
  padding-top: $md-default-padding;

  .selectionControlGroup {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}
