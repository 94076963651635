@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('OpenSans Regular '),
    local('OpenSans-Regular'),
    url('./files/OpenSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/OpenSans-Regular.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('OpenSans SemiBold '),
    local('OpenSans-SemiBold'),
    url('./files/OpenSans-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/OpenSans-SemiBold.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
          local('OpenSans Bold '),
          local('OpenSans-Bold'),
          url('./files/OpenSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
          url('./files/OpenSans-Bold.woff') format('woff'); /* Modern Browsers */
}