@import "~scss/variables";

.container {
  padding: $md-default-padding;
  padding-top: 1px; // fix scroll glitch

  .infoBoxContainer {
    padding-top: 2*$md-default-padding;
  }

  .link {
    text-decoration: underline !important;
  }
}