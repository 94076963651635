@import "~scss/variables";

.dialog {
  width: 90vw !important;
  max-width: 90vw !important;

  p {
    font-size: 16px
  }
}

.container {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}