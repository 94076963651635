@import "~scss/variables";

$md-colors-warn-md: false;

.theme-demo-insurance {
  --primary-color: red;
  --secondary-color: var(--sensotix-green);
  --tertiary-color: var(--sensotix-green);

  --primary-background-color: white;
  --primary-text-color: black;
  --secondary-background-color: var(--sensotix-green);
  --secondary-text-color: white;
  --tertiary-background-color: var(--sensotix-green);
  --tertiary-text-color: white;
  --default-main-color: black;
  --default-sub-color: white;

  @include react-md-theme-everything(
    var(--primary-background-color),
    var(--secondary-color),
    true,
    null,
    $md-error-color,
    $md-linear-progress-swatch,
    $md-primary-hover-opacity,
    var(--primary-text-color),
    $md-secondary-hover-opacity,
    $md-secondary-background-text-color,
    $md-data-table-contextual-fallback-color,
    $md-linear-progress-fallback-color,
    $md-switch-ball-fallback-color
  );
  @include react-md-progress-linear($secondary-color: $md-red-a-700, $swatch: 100);

  .md-subheader {
    font-size: 16px;
    color: var(--primary-text-color);
  }

  .md-tab--inactive {
    color: inherit;
  }

  .md-progress--linear {
    margin-top: 0;
    height: 8px;
  }

  .md-btn--text {
    text-transform: none;
    font-size: 18px;
  }

  .md-floating-label--floating { // make floating labels above Input fields mono-colored at bigger
    font-size: 14px;
    color: darkgray;
  }
}