@import "~scss/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: $md-default-padding/2;

  .footer {
    font-size: 16px;
    background: var(--primary-background-color);
    color: var(--helvetia-gray);
    padding: $md-default-padding $md-default-padding/2 80px $md-default-padding/2;
    //border-radius: 0 0 12px 12px;

    p {
      height: 10px;
    }

    strong {
      letter-spacing: -0.8px;
    }

    small {
      font-size: 14px;
    }
  }
}