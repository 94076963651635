@import "scss/globals";

/* sanitize behaviour to be consistent among all recent browsers */
@import '~sanitize.css/sanitize.css';

/* fonts */
@import "fonts/typeface-roboto/roboto-fonts.css";
@import "fonts/material-icons/iconfont/material-icons.css";

/* custom react-image-gallery styles */
@import "~react-image-gallery/styles/scss/image-gallery.scss";


/* video.js */
@import '~video.js/dist/video-js.min.css';

html, body {
  font-family: Roboto, sans-serif;
  //overflow: hidden; //TODO: fix issue that additional click is needed!
}

body {
  overflow: auto; // needed for inobounce.js
  -webkit-overflow-scrolling: touch; // needed for inobounce.js
}

// Drawer: keep width at 320px
.md-drawer {
  min-width: 320px;
  max-width: 320px;
}

// Make dialog text legible
.md-dialog-content {
  font-size: 16px;
}

// Fix selection dropdown (languages)
.md-select-field__toggle {
  .md-paper--1 {
    box-shadow: none;
  }
  .md-select-field--btn.md-select-field--btn {
    padding-left: 0;
    padding-right: 0;
  }
}

// Drawer: restrict width to 320px
@media screen and (max-width: 1024px) and (min-width: 320px) {
  .md-drawer--right {
    left: auto !important;
  }
}
.md-list {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

// fix wrong width if dialog opened inside tab slide (position: fixed is the issue)
.md-no-scroll.md-no-scroll {
  width: 0;
}

/* fix wrong color */
.vjs-poster {
  background-color: var(--placeholder-color-extralight)
}
.vjs-control-bar {
  z-index: 1000; // fix Safari iOS issue where video overlays the control bar
}
.pnlm-tooltip {
  // fix glitch of too small font and too narrow tooltip
  span {
    width: unset !important;
    font-size: 16px
  }
}
// make round play button
.vjs-big-play-centered .vjs-big-play-button {
  margin-left: -0.75em;
  width: 1.5em;
  border-radius: 1em;
  line-height: 1.4em;
}
.vjs-remaining-time-display {
  font-size: 14px;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 20px;
}
.vjs-control-bar {
  height: 32px;
}
