/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url('./files/roboto-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url('./files/roboto-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url('./files/roboto-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-700.woff') format('woff'); /* Modern Browsers */
}