@import "~scss/variables";

.container {

  :global(.geosuggest) {
    width: 100%;
    font-size: 16px;
    position: relative;
    margin: 1em auto;
    text-align: left;
  }
  :global(.geosuggest__input) {
    font-size: 16px;
    background: inherit;
    width: 100%;
    border: none; //2px solid transparent;
    box-shadow: none; //0 0 1px #3d464d;
    padding: 0; //.5em 1em;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
    transition: border 0.2s, box-shadow 0.2s;
    outline: none;
  }
  :global(.geosuggest__input:focus) {
    border-color: #ddd;
    box-shadow: 0 0 0 transparent;
  }
  :global(.geosuggest__input::placeholder) {
    font-size: 16px;
  }
  :global(.geosuggest__suggests) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #ddd;
    border-top-width: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
  }
  :global(.geosuggest__suggests--hidden) {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  /**
   * A geosuggest item
   */
  :global(.geosuggest__item) {
    font-size: 16px;
    padding: .5em .65em;
    cursor: pointer;
  }
  :global(.geosuggest__item:hover),
  :global(.geosuggest__item:focus) {
    background: #f5f5f5;
  }
  :global(.geosuggest__item--active) {
    background: #267dc0;
    color: #fff;
  }
  :global(.geosuggest__item--active:hover),
  :global(.geosuggest__item--active:focus) {
    background: #ccc;
  }
  :global(.geosuggest__item__matched-text) {
    font-weight: bold;
  }

  .wrapper {
    padding-top: 40px;

    .suggestion {
      display: flex;
      flex-direction: row;
      border: #ccc 1px solid;
      border-radius: 4px;
      align-items: center;
      height: 40px;
      font-size: 16px;
      background: white;

      .geosuggestWrapper {
        flex: 1 1;
      }

      .button {
        flex: 0 0 46px;
      }
    }

    .placeholder {
      font-size: 16px;
      color: var(--placeholder-color-dark);
    }
  }
}