.optionList {
  width: 100%;

  :global(.md-list-item) {

    :global(.md-tile-addon--icon) {
      height: auto; // necessary to keep icons on same line as text
    }

    :global(.md-text-field-container--input) {
      margin: 0;  // keep input line at left side
    }

    :global(.md-text--disabled) {
      color: black;
    }

    :global(.md-tile-content--left-icon) {
      padding-left: 12px; // move text closer to the drag handle
    }
  }
}

.sortableHocHelper {
  z-index: 10000;
}