.placeholder {
  background-color: var(--placeholder-color-extralight);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  height: 100%;

  i {
    color: var(--placeholder-color);
    font-size: 100px;
  }

  p {
    color: var(--placeholder-color-dark);
  }
}