@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: column;

  .viewHeader {
    display: flex;
    flex-direction: row;
    margin-top: 8px;

    .flag {
      flex: 0 36px;
    }

    .content {
      flex: 1;

      .label {
        font-size: 16px;
      }
    }

    .icon {
      flex: 0 24px;
      margin-top: -4px;
    }
  }

  .viewImage {
    flex: 1;
    font-size: 16px;
    position: relative;
    background-color: var(--placeholder-color-extralight);
    height: $md-thumbnail-max-height;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .viewFooter {
    .description {
      color: var(--placeholder-color-dark);
      margin-top: 8px;
      font-size: 15px;
    }
  }


}

.editContainer {
  padding: $md-default-padding;
}