@import "~scss/variables";

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .body {
    flex: 1 1 auto;
    //position: relative;
    background: var(--tertiary-color);

    .top {
      height: 220px;
      //text-align: right;
      padding-top: $md-default-padding;
      background: var(--primary-background-color);

      .header {
        //position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 450px;

        .menu {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          button {
            color: var(--primary-text-color);
            margin-left: -14px;
          }
        }

        h3 {
          font-weight: 700;
          padding-top: $md-default-padding;
          color: var(--primary-text-color);
        }

        p {
          font-weight: 400;
          color: var(--primary-text-color);
        }
      }
    }

    .tabsContainer {
      margin-top: -36px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      min-width: 280px;
      max-width: 500px;

      .tab {
        display: none;
      }
    }

    .footer {
      text-align: center;
      color: var(--default-sub-color);
      position: absolute;
      bottom: $md-default-padding/2;
      left: 0;
      right: 0;
    }
  }
}

/*@media (max-height: 380px) and (orientation: landscape) {
  .header {
    h3, p {
      display: none;
    }
  }
  .page .body .top {
    height: 110px;
  }
}*/

/*
@media (max-height: 420px) and (orientation: landscape) {
  .header {
    h3, p {
      display: none;
    }
  }

  .page .body .top {
    height: 100px;
  }

  .page .body .tabsContainer {
    margin-top: -36px;
  }
}

@media (max-height: 420px) and (orientation: landscape) {
  .page .body .tabsContainer {
    margin-top: -36px;
    max-height: calc(100vh - 100px + 36px - 40px);
  }
}*/