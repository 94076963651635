@import "~scss/variables";

.card {
  font-size: 18px;
}

.container {
  position: relative;
  padding: $md-default-padding;
  padding-bottom: 80px;

  .infoBoxContainer {
    margin: $md-default-padding $md-default-padding 0 $md-default-padding;
  }
}
