@import "~scss/variables";

.viewContainer {
  //padding: $md-default-padding;

  label {
    font-size: 16px !important;
    //font-weight: 500;
    color: #444 !important;
  }

  legend {
    font-size: 16px !important;
    //font-weight: 500;
    color: #444 !important;
  }

  input:not(:global(.geosuggest__input)) {
    //font-weight: 500 !important;
  }
}

.tertiaryButton {
  text-align: center;
  background-color: var(--primary-color);
  margin-top: $md-default-padding/2;
}

.selectFieldEditInput {
  span {
    flex-direction: row;
    border: #ccc 1px solid;
    border-radius: 4px;
    -webkit-align-items: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
    background: white;
    padding-left: 16px;
    padding-top: 8px;
    margin-right: -24px;
    background: var(--helvetia-selected);
  }

  :global(.md-ink-container) {
    display: none;
  }
}

.spacing {
  margin-top: 1*$md-default-padding;
}

.spacingSmall {
  margin-top: 0.5*$md-default-padding;
}

.locationPickerSuggestion {
  background: var(--helvetia-selected) !important;
}
.locationPickerSuggestionButton {
  color: var(--helvetia-purple) !important;
}

.datetimePickerContainer {
  input, span {
    background: var(--helvetia-selected);
    border-left: none;
  }
}

.textFieldEdit {
  border: #ccc 1px solid;
  border-radius: 4px;
  margin: $md-default-padding/4 0;
  //background: var(--placeholder-color-extralight);
  background: var(--helvetia-selected);
  padding: 0 $md-default-padding/2 $md-default-padding/4 $md-default-padding/2;

  hr {
    display: none;
  }

  textarea {
    &::placeholder {
      color: var(--placeholder-color-dark);
    }
  }
}