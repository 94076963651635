@import "~scss/variables";

.card {
  font-size: 18px;
  height: 100%;

  > ul {
    height: 100%;
  }
}

.content {
  ul > div {
    padding: $md-default-padding/2 $md-default-padding;
  }
}
