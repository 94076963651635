@import "~scss/variables";

.container {
  display: block;
  //margin: $md-default-padding/2 $md-default-padding/2 0 $md-default-padding/2;
  height: 100%;
}

.card {
  background: white;
  border: 1px solid var(--placeholder-color-light);
  list-style: none;
}