@import "~scss/variables";

.textFieldEdit {
  border: #ccc 1px solid;
  border-radius: 4px;
  margin: $md-default-padding/4 0;
  background: var(--helvetia-selected);
  padding: 0 $md-default-padding/2 $md-default-padding/4 $md-default-padding/2;

  hr {
    display: none;
  }
}

.containerTextFieldEdit {
  margin-top: $md-default-padding;
}

.button {
  text-align: center;
  background-color: var(--primary-color);
  margin-top: 2*$md-default-padding;
}