@import "~scss/variables";

.toolbar {
  min-height: 50px;

  .logo {
    padding: $md-default-padding/2;
    text-align: right;

    img {
      height: 30px;
      padding: 4px;
    }
  }
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  .tab {
    display: none;
  }

  .swipeableViewsContainer {
    flex: 1;
  }

  main {
    padding-top: 1px; // fix scroll glitch
  }
}