@import '~react-md/src/scss/react-md';

$md-light-theme: true; // optional for light theme
$md-primary-color: $md-cyan-600;
$md-secondary-color: $md-red-a-200;
$primary-text-color: $md-white-base;
$secondary-text-color: $md-white-base;

$md-toolbar-height: 50px; // important to have fixed height on all screens;
$md-toolbar-icontabs-height: 122px;
$md-ios-bottom-bar: 44px;
$md-ios-offset: 80px;
$md-thumbnail-max-height: 160px;
$md-font-icon-include-dense: false; // important to keep icons always the same size

//$md-menu-max-height: 320px; // otherwise floating button menu gets truncated