@import "~scss/variables";

.viewContainer {
  display: flex;
  flex-direction: row;
  font-weight: normal;

  .flag {
    flex: 0 36px;
  }

  .label {
    flex: 1;
    font-size: 16px;
    font-style: italic;
  }

  .icon {
    flex: 0 24px;
  }


  blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    width: 500px;
    margin: 0.25em 0;
    padding: 0 30px;
    line-height: 1.45;
    position: relative;
    color: #383838;
  }

  blockquote:before {
    display: block;
    padding-left: 16px;
    content: "\201C";
    font-size: 48px;
    position: absolute;
    left: -16px;
    top: -16px;
    color: #7a7a7a;
  }

  blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }
}

.editContainer {
  padding: $md-default-padding;
}