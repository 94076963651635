@import "~scss/variables";

.toolbar {
  //border-radius: 12px 12px 0 0;
  color: black;

  .logo {
    padding: $md-default-padding/2;
    text-align: right;

    img {
      width: 90%;
    }
  }
}

.container {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  .tab {
    display: none;
  }

  .swipeableViewsContainer {
    flex: 1;
  }

  main {
    padding-top: 1px; // fix scroll glitch
  }

  .infoBoxContainer {
    margin: 2*$md-default-padding $md-default-padding $md-default-padding $md-default-padding;
  }

  .link {
    text-decoration: underline !important;
  }
}