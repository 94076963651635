.container {
  position: relative;

  i {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.8;
    font-size: 28px;
  }
}