.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .datetimePickerContainer {
    input {
      color: black !important;
      font-size: 22px;
    }
  }

  .button {
    width: 100%;
    font-size: 22px;
    height: 60px;
    text-transform: none;
  }

  :global(.md-subheader) {
    padding-left: 0;
  }

  li {
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;

  }
}